<template>
  <div class="w-full relative">
    <splide
      class="2xl:h-90vh"
      :options="options"
      :slides="slideContent"
    >
      <splide-slide
        v-for="hero in slideContent"
        :key="hero.title"
        class="flex items-center 2xl:h-90vh"
        :class="{'h-70vh': !isHomePage, 'h-85vh': isHomePage }"
      >
        <div class="max-w-screen-xl mx-auto text-white z-10">
          <div class="w-screen px-6 pr-3">
            <h1 class="text-5xl w-1/2 lg:w-1/3 lg:text-6xl xl:text-8xl font-bold">{{hero.title}}</h1>
            <h2
              class="text-2xl lg:text-3xl font-thin mt-2 break-words"
              v-if="hero.subTitle"
            >{{hero.subTitle}}</h2>
            <div
              class="flex items-center space-x-4 mt-6"
              v-if="hero.link"
            >
              <router-link :to="hero.link">
                <div class="w-16">
                  <svg
                    viewBox="0 0 132 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      y1="1.5"
                      x2="132"
                      y2="1.5"
                      stroke="white"
                      stroke-width="3"
                    />
                  </svg>
                </div>
              </router-link>
              <router-link :to="hero.link">
                <span class="spaced">
                  EXPLORE MORE
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="absolute h-full w-full">
          <img
            class="object-cover object-center"
            :src="hero.image"
          />
        </div>
      </splide-slide>
    </splide>
  </div>
</template>
<script src="./hero.js"></script>
<style src="./hero.css" scoped lang="css"></style>

