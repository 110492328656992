import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Hero",
  props: {
    slideContent: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();

    const options = {
      perPage: 1,
      speed: "1000",
      type: "loop",
      arrows: false,
      drag: false,
      pagination: false,
      autoplay: (props.slideContent.length > 1 ? true : false),
    };

    return { options, isHomePage: route.name == 'Nasarawa Investment & Development Agency' };
  },
});
