<template>
  <div id="our-governance">
    <div class="governance_body mt-10">
      <div class="mb-16">
        <div class="sub_headings text-6xl">Documents</div>
        <!-- <div class="mt-6 text-xl">
          View the reports
        </div> -->
      </div>
      <SummitLink
        v-for="f in filesrcs"
        @click="goto(f.src)"
        :key="f.title"
        :name="f.title"
        :link="f.src"
        :title="f.time"
      ></SummitLink>
    </div>
  </div>
</template>

<script src="./documents.page.js"></script>
<style src="./documents.page.css" scoped lang="css"></style>
